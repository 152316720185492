import { FC, Suspense, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SolasLayout } from '@britishcouncil/react-solas';
import { ErrorBoundary } from '@sentry/react';
import { PageLoader } from '@britishcouncil/react-solas-ors3';
import styled from '@emotion/styled';
import { Header, Footer, ErrorPage, LoadingCard, NearEndMsg } from 'ors-ui';
import { iolLinks } from 'ors-utils';
import { useSelectedLangDirection } from 'ors-translations';

import { AppRoutes } from 'routing/routes';
import { UserNav, CommonModals, LiveAgent, Crowdin } from './components';
import { usePageViewsInGA, useSelector, useOrganisations } from 'core';
import { searchSelect } from 'store/searchSelect';
import { useI18n } from 'common/useI18n';
import { useDispatch } from 'core/hooks/useDispatch';
import { initApp } from 'store/initApp';

import './App.scss';
import { useAuthSlice } from 'store/authSlice';
import { OidcContext } from '@britishcouncil/react-oidc-auth';
import { Helmet } from 'react-helmet';

const App: FC = () => {
  usePageViewsInGA();
  useI18n();

  const dispatch = useDispatch();
  const { showApp, logoUrl, orgData, extraLogoUrl, faviconUrl } = useOrganisations();
  const { isLoggedIn } = useSelector((s) => s.auth);
  const { handleLogOut, subscribeToUserLoaded } = useAuthSlice();
  const { isLoading, user, removeUser } = OidcContext.useAuth();
  const [isAppStarting, setIsAppStarting] = useState(true);
  const dir = useSelectedLangDirection();

  useEffect(() => {
    const init = async () => {
      await dispatch(initApp(handleLogOut));
      subscribeToUserLoaded();
      setIsAppStarting(false);
    };
    init();
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && !!user && !isLoggedIn) {
      removeUser();
    }
  }, [isLoading]);

  const isIol = useSelector(searchSelect.selectors.isIol);
  const { showNearEndNotification, minutesLeft } = useSelector((s) => s.reservation);

  return (
    <ErrorBoundary
      fallback={() => (
        <ErrorPage
          logoUrl={logoUrl}
          extraLogoUrl={extraLogoUrl}
          faviconUrl={faviconUrl}
          link={Link}
        />
      )}
    >
      <Helmet>
        <link
          href="https://bccdn.britishcouncil.org/dev/solas/0.1.6-bc.6/bc-ors-theme.css"
          rel="stylesheet"
        />
      </Helmet>

      <Crowdin />
      <SolasLayout id="app-container" ieltsBranding slogan="ielts" flex dir={dir}>
        {showApp && !isAppStarting ? (
          <>
            <Header
              slogan="IELTS"
              slim
              compactMobileMode
              logoUrl={logoUrl}
              extraLogoUrl={extraLogoUrl}
              faviconUrl={faviconUrl}
              link={Link}
            >
              <UserNav />
            </Header>
            <div className="app-contents">
              <Suspense fallback={<LoadingCard />}>
                <AppRoutes />
                <CommonModals />
              </Suspense>
            </div>
            <LiveAgent />
            <NearEndMsg showMsg={showNearEndNotification} minsLeft={minutesLeft} />
            <Footer iolLinks={isIol ? iolLinks.footer : undefined} orgSpecificData={orgData} />
          </>
        ) : (
          <LoaderWrapper>
            <PageLoader />
          </LoaderWrapper>
        )}
      </SolasLayout>
    </ErrorBoundary>
  );
};

export default App;

// TODO: <PageLoader> doesn't accept a `style` prop, so we need to wrap it in a div. Fix it in `react-components`
const LoaderWrapper = styled.div`
  margin-top: 30%;
`;
