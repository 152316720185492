import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { MainContainer, PageContainer, Quote } from '@britishcouncil/react-solas-ors3';
import { ExamOptionType, ProductsInCentre } from 'ors-api/mod';
import { TermsShortCodeEnum, icLinks, makeTermsUrl } from 'ors-utils';
import { hasIeltsUsaUrl } from 'ors-translations';
import { ExternalLink, HtmlTextRaw, PageHeading } from 'ors-ui';
import { IELTSProducts } from 'ors-api/iol';

import { appRoutes, useNavigateTo } from 'routing';
import { useOrganisations, useSelector } from 'core';
import { useDispatch } from 'core/hooks/useDispatch';
import { searchSelect } from 'store/searchSelect';
import { proceed } from 'store/startPage';
import { NarrowQuote } from 'components';
import { OrganisationNotes } from '../StartPage/OrganisationNotes';
import { BottomSection, StyledExamTypeBtn, StyledList, WhichIeltsLabel } from '../StartPage/styles';
import styled from '@emotion/styled';

const ChooseUkvi = () => {
  const { t } = useTranslation();
  const navigate = useNavigateTo();
  const dispatch = useDispatch();

  const { orgData, showApp } = useOrganisations();

  const { isLoading: loadingIolAvailability } = useSelector((s) => s.searchSelect.iolAvailable);

  const showLifeSkillsA2 = !hasIeltsUsaUrl();

  /** Not the best readability, but sometimes we will have to use type guards to determine proper type */
  const termsAndConditions =
    (orgData &&
      makeTermsUrl({
        withBase: true,
        shortCode: orgData?.globalTermsAndConditions?.shortCode ?? TermsShortCodeEnum.Global_UKVI,
      })) ||
    makeTermsUrl({ withBase: true, shortCode: TermsShortCodeEnum.Global_UKVI });

  const bottomNote = orgData?.bottomLandingPageCustomText;
  const handleNavigation = useCallback(
    (
      module: 'ac' | 'gt',
      examOptionType: ExamOptionType,
      productId: IELTSProducts | ProductsInCentre
    ) => {
      dispatch(searchSelect.actions.setExamType({ examType: module }));
      dispatch(
        searchSelect.actions.setUkviSpecificData({
          examOptionType,
        })
      );
      dispatch(searchSelect.actions.updateSelectedProductId(productId));
      dispatch(searchSelect.actions.resetFilter());
      navigate(appRoutes.search.findTest);
    },
    [dispatch, navigate]
  );
  const handleNavigationUol = useCallback(
    (module: 'ac' | 'gt', examOptionType: ExamOptionType) => {
      dispatch(
        searchSelect.actions.setUkviSpecificData({
          examOptionType,
        })
      );
      dispatch(proceed(module, true));
    },
    [dispatch]
  );

  return (
    <article>
      <PageHeading heading={t('APPB2C.cj.ukviStartPage.title')} />
      <NarrowQuote style={{ marginBottom: 30 }}>
        <Quote intent="gray" title={t('APPB2C.cj.ukviStartPage.note1.title')}>
          <Trans t={t} i18nKey="APPB2C.cj.ukviStartPage.note1.content">
            <Link to={appRoutes.root} />
          </Trans>
        </Quote>
      </NarrowQuote>
      <PageContainer loading={!showApp || loadingIolAvailability}>
        <OrganisationNotes orgSpecificData={orgData} />
        <p>{t('APPB2C.cj.ukviStartPage.testsBelow')}</p>
        <StyledExamTypeBtn
          title={t('APPB2C.cj.ukviStartPage.ac.title')}
          description={t('APPB2C.cj.ukviStartPage.ac.content')}
          onClick={() => handleNavigationUol('ac', ExamOptionType.IELTSUKVILRW)}
          id="select-ukvi-ac"
        />
        <StyledExamTypeBtn
          title={t('APPB2C.cj.ukviStartPage.gt.title')}
          description={t('APPB2C.cj.ukviStartPage.gt.content')}
          onClick={() => handleNavigationUol('gt', ExamOptionType.IELTSUKVILRW)}
          id="select-ukvi-gt"
        />
        <div style={{ margin: '40px 0' }}>
          <HeaderLS>{t('APPB2C.cj.ukviStartPage.lifeSkils.title.v2')}</HeaderLS>
          <p>{t('APPB2C.cj.ukviStartPage.lifeSkils.visaTypes')}</p>
          <StyledList>
            <li>{t('APPB2C.cj.ukviStartPage.lifeSkils.family')}</li>
            <li>{t('APPB2C.cj.ukviStartPage.lifeSkils.extension')}</li>
            <li>{t('APPB2C.cj.ukviStartPage.lifeSkils.citizenship')}</li>
          </StyledList>
          <Quote title={t('APPB2C.cj.ukviStartPage.lifeSkils.note.title')} intent="gray">
            {t('APPB2C.cj.ukviStartPage.lifeSkils.note.content.v2')}
          </Quote>
        </div>
        <StyledExamTypeBtn
          title={t('APPB2C.cj.ukviStartPage.lifeSkils.a1.title.v2')}
          description={t('APPB2C.cj.ukviStartPage.lifeSkils.a1.content')}
          onClick={() =>
            handleNavigation('ac', ExamOptionType.LifeSkillsA1, ProductsInCentre.UKVILifeSkillsA1)
          }
          id="select-ls-a1"
        />
        {showLifeSkillsA2 && (
          <StyledExamTypeBtn
            title={t('APPB2C.cj.ukviStartPage.lifeSkils.a2.title.v2')}
            description={t('APPB2C.cj.ukviStartPage.lifeSkils.a2.content')}
            onClick={() =>
              handleNavigation('ac', ExamOptionType.LifeSkillsA2, ProductsInCentre.UKVILifeSkillsA2)
            }
            id="select-ls-a2"
          />
        )}
        <StyledExamTypeBtn
          title={t('APPB2C.cj.ukviStartPage.lifeSkils.b1.title.v2')}
          description={t('APPB2C.cj.ukviStartPage.lifeSkils.b1.content')}
          onClick={() =>
            handleNavigation('ac', ExamOptionType.LifeSkillsB1, ProductsInCentre.UKVILifeSkillsB1)
          }
          id="select-ls-b1"
        />
        <WhichIeltsLabel data-testid="which-ielts">
          <ExternalLink className="link" href={orgData?.versionOfIeltsUrl ?? icLinks.whichUkvi}>
            {t('APPB2C.cj.ukviStartPage.whichUkvi')}
          </ExternalLink>
        </WhichIeltsLabel>
      </PageContainer>
      <MainContainer>
        <BottomSection>
          <p>
            <Trans t={t} i18nKey="APPB2C.cj.ukviStartPage.terms">
              <ExternalLink href={termsAndConditions} />
            </Trans>
          </p>
          {bottomNote && <HtmlTextRaw htmlText={bottomNote} />}
        </BottomSection>
      </MainContainer>
    </article>
  );
};

export default ChooseUkvi;

const HeaderLS = styled.p`
  font-size: 1.11em;
  line-height: 1.3;
  font-weight: 700;

  [dir='rtl'] & {
    font-weight: 500;
  }
  margin: 0 0 17px;
  font-family: 'Work Sans', Arial, 'Helvetica Neue', Helvetica, sans-serif;
`;
