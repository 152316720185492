import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BsFunnel } from 'react-icons/bs';
import { SelectedOptionValue } from 'react-select-search';
import styled from '@emotion/styled';
import { LinkButton, MainContainer } from '@britishcouncil/react-solas-ors3';
import { ExamFormat, TimeTableSession } from 'ors-api/ors2';

import { getRegFlow, GTM, useSelector } from 'core';
import { setSortOrder } from 'store/bookTest/thunks/thunks.common';
import { invitation } from 'store/invitation';
import { searchSelect } from 'store/searchSelect';
import { SortByFilter } from './components';

interface Props {
  onOpenFilters: () => void;
  disabled?: boolean;
}

export const FilterSection: FC<Props> = ({ onOpenFilters, disabled }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { needSpecialReqs, filter, locationCoords } = useSelector((state) => state.searchSelect);
  const { sortOrder } = useSelector((state) => state.bookTest);
  const isUkviLifeSkills = useSelector(searchSelect.selectors.isUkviLifeSkills);
  const agentPays = useSelector(invitation.selectors.agentPays);
  const regFlow = getRegFlow();

  const canOpenFilters = useMemo(
    () => !(needSpecialReqs || isUkviLifeSkills),
    [needSpecialReqs, isUkviLifeSkills]
  );

  const filterSummary = useMemo(() => {
    const defaultSummary = `${t('APPB2C.common.bookTest.filters.paper')} & ${t(
      'APPB2C.common.bookTest.filters.computer'
    )}`;

    if (!filter) {
      return defaultSummary;
    }

    const { examFormat, timeTableSessions } = filter;
    const [isMorning, isAfternoon, isEvening] = [
      !!timeTableSessions?.find((s) => s === TimeTableSession.A),
      !!timeTableSessions?.find((s) => s === TimeTableSession.P),
      !!timeTableSessions?.find((s) => s === TimeTableSession.E),
    ];
    const isAnyTime =
      (!isMorning && !isAfternoon && !isEvening) || (isMorning && isAfternoon && isEvening);

    return (
      <>
        <span className="filter-value">
          {examFormat === ExamFormat.PB || !examFormat || needSpecialReqs
            ? t('APPB2C.common.bookTest.filters.paper')
            : ''}
          {!examFormat && !needSpecialReqs && <> &amp; </>}
          {!needSpecialReqs && (!examFormat || examFormat === ExamFormat.CD)
            ? t('APPB2C.common.bookTest.filters.computer')
            : ''}
        </span>
        {!needSpecialReqs && (!examFormat || examFormat === ExamFormat.CD) && (
          <>
            {isAnyTime ? (
              <span className="hide-mobile">
                <DotDivider />
                {t('APPB2C.common.bookTest.filters.anyTime')}
              </span>
            ) : (
              <span className="hide-mobile">
                <DotDivider />
                {isMorning && <>{t('APPB2C.common.bookTest.filters.morning')}</>}
                {isAfternoon && (
                  <>
                    {isMorning && <> &amp; </>}
                    {t('APPB2C.common.bookTest.filters.afternoon')}
                  </>
                )}
                {isEvening && (
                  <>
                    {(isMorning || isAfternoon) && <> &amp; </>}
                    {t('APPB2C.common.bookTest.filters.evening')}
                  </>
                )}
              </span>
            )}
          </>
        )}
      </>
    );
  }, [filter, needSpecialReqs, t]);

  const onFilterChange = (selectedValue: SelectedOptionValue) => {
    GTM.trackRegistrationEvent('changeSorting', { filter_sortBy: selectedValue as any });
    dispatch(setSortOrder(selectedValue as any));
  };

  return (
    <FilterWrapper>
      {canOpenFilters && (
        <FilterContainer>
          <StyledFunnelIcon aria-hidden="true" />
          <div className="filter-label">{t('APPB2C.common.bookTest.filters.title')}:</div>
          <FilterSummary data-testid="filter-results">{filterSummary}</FilterSummary>
          <FilterLink
            disabled={disabled}
            onClick={() => (!disabled ? onOpenFilters() : null)}
            data-testid="show-all-filters"
            id="btn-showAllFilters"
          >
            {t('APPB2C.common.bookTest.filters.seeAllFilters')}
          </FilterLink>
        </FilterContainer>
      )}
      {
        <SortByFilter
          sortOrder={sortOrder}
          isLocationCoordsSelected={!!locationCoords}
          agentPays={agentPays}
          regFlow={regFlow}
          onChange={onFilterChange}
        />
      }
    </FilterWrapper>
  );
};

export const DotDivider: FC = () => (
  <span
    style={{
      margin: '0 10px',
      fontWeight: 'bold',
    }}
  >
    •
  </span>
);

const FilterWrapper = styled(MainContainer)`
  @media (min-width: 850px) {
    max-width: 958px;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border: 2px solid #005cb9;
  border-radius: 100px;
  padding: 18px 20px;
  margin-bottom: 15px;
  max-height: 65px;

  .filter-label {
    flex: 1;
    white-space: nowrap;
    margin-right: 10px;
  }

  .filter-value {
    white-space: nowrap;
  }

  @media (max-width: 600px) {
    .filter-label {
      display: none;
    }

    .hide-mobile {
      display: none;
    }
  }
`;

const FilterSummary = styled.div`
  line-height: 17px;
  color: #333;
  font-weight: bold;
  font-size: 16px;
  max-height: 65px;
  overflow: hidden;
  flex: 5;

  [dir='rtl'] & {
    font-weight: 500;
  }
`;

const StyledFunnelIcon = styled(BsFunnel)`
  color: #005cb9;
  margin: 0 15px 0 0;
  width: 24px;
  height: 24px;
  flex: 0 0 20px;
`;

const FilterLink = styled(LinkButton)`
  align-self: flex-end;
  white-space: nowrap;
  max-height: 30px;
  margin: 0 -5px 0 10px;
`;
