import { MarketingQuestionDto } from 'ors-api/mod';
import {
  AnswerInvalid,
  ApiErrorsList,
  ErrorMessages,
  reduceMultipleErrorSources,
  commonErrorCodes,
  commonErrorProps,
  commonMaxLengths,
} from 'ors-ui';

import { appRoutes } from 'routing';
import i18next from '../i18n';
import {
  personalDetailsErrorCodes,
  idDetailsErrorCodes,
  registrationErrorCodes,
} from './apiErrorCodes';

type CodeOrProp = { code: string | null; property: string | null | undefined };
type ErrorMessageRegistrationProps = {
  errors: (ApiErrorsList | undefined)[];
  setShowRegistrationFailedModal: (val: boolean) => void;
  setRegistrationFailedCustomMessage: (val: string) => void;
  setShowMissingMarketingError: (val: boolean) => void;
  setMissingMarketingRoute: (val: string) => void;
  setShowChangedFeeModal: (val: boolean) => void;
  allMarketingQuestions?: MarketingQuestionDto[];
  marketingAsnwerIds?: number[];
};

export const errors = {
  tErrorMessageResetPassword: (error: ApiErrorsList | undefined): ErrorMessages | undefined => {
    if (!error) return undefined;

    return (error?.errors || []).map((e) => {
      switch (e.message) {
        case 'The password you entered does not meet the requirements.':
          return i18next.t('APPB2C.auth.resetPassword.error.wrongPassword');
        case 'Link to reset your password has expired. Please use "Forgot Password" on login screen to receive new link.':
          return i18next.t('APPB2C.auth.resetPassword.error.expiredToken');
        case 'Your temporary link for password reset is invalid.':
          return i18next.t('APPB2C.auth.resetPassword.error.wrongToken');

        default:
          return null;
      }
    });
  },
  tErrorMessageChildDetails: (error: ApiErrorsList | undefined): ErrorMessages | undefined => {
    if (!error) return undefined;

    return (error?.errors || []).map((e) => {
      const { code, property } = e;

      switch (code) {
        case commonErrorCodes.NotEmptyValidator:
          switch (property) {
            case commonErrorProps.firstName:
              return i18next.t('APPB2C.cj.childDetails.error.missingFirstName');
            case commonErrorProps.surname:
              return i18next.t('APPB2C.cj.childDetails.error.missingSurname');
            case personalDetailsErrorCodes.dob:
              return i18next.t('APPB2C.cj.childDetails.error.wrongDob');

            default:
              return null;
          }
        case commonErrorCodes.PredicateValidator:
          if (property === personalDetailsErrorCodes.dob)
            return i18next.t('APPB2C.cj.childDetails.error.wrongDob');
          return null;

        default:
          return null;
      }
    });
  },
  tErrorMessageReservation: (error: ApiErrorsList | undefined): ErrorMessages | undefined => {
    if (!error) return undefined;

    return (error?.errors || []).map((e) => {
      if (
        e.code === personalDetailsErrorCodes.TestTakerFailure &&
        e.property === personalDetailsErrorCodes.plannedExamId
      )
        return i18next.t('APPB2C.cj.personalDetails.error.doubleBooking');

      return null;
    });
  },
  tErrorMessageIdDetails: (errors: (ApiErrorsList | undefined)[]): ErrorMessages | undefined => {
    const allErrors = reduceMultipleErrorSources(errors);

    if (!allErrors) return undefined;

    return allErrors.map((e) => {
      const { code, property } = e;

      switch (code) {
        case commonErrorCodes.NotNullValidator:
          if (property === commonErrorProps.idExpiry)
            return i18next.t('APPB2C.cj.idDetails.error.missingIdExpiry');
          return null;
        case commonErrorCodes.PredicateValidator:
          if (property === commonErrorProps.idExpiry)
            return i18next.t('APPB2C.cj.idDetails.error.wrongIdExpiry');
          return null;
        case commonErrorCodes.RegularExpressionValidator:
        case commonErrorCodes.NotEmptyValidator:
          switch (property) {
            case commonErrorProps.idNumber:
              return i18next.t('APPB2C.cj.idDetails.error.missingIdNumber');
            case commonErrorProps.issuingAuthority:
              return i18next.t('APPB2C.cj.idDetails.error.missingIssuingAuthority');

            default:
              return null;
          }
        case idDetailsErrorCodes.InvalidFileName:
          return i18next.t('APPB2C.cj.idDetails.error.wrongFilename');

        default:
          return null;
      }
    });
  },
  tErrorMessagePersonalDetails: (
    errors: (ApiErrorsList | undefined)[],
    setShowEmailInUse?: (val: boolean) => void
  ): ErrorMessages | undefined => {
    const allErrors = reduceMultipleErrorSources(errors);

    if (!allErrors) return undefined;

    return allErrors.map((e) => {
      const { code, property } = e;

      switch (code) {
        case commonErrorCodes.NotEmptyValidator:
          switch (property) {
            case commonErrorProps.firstName:
              return i18next.t('APPB2C.cj.personalDetails.error.missingFirstName');
            case commonErrorProps.surname:
              return i18next.t('APPB2C.cj.personalDetails.error.missingSurname');
            case commonErrorProps.email:
              return i18next.t('APPB2C.cj.personalDetails.error.missingEmail');
            case personalDetailsErrorCodes.confirmEmail:
              return i18next.t('APPB2C.cj.personalDetails.error.missingEmailConfirm');

            default:
              return null;
          }
        case commonErrorCodes.EmailValidator:
          if (property === commonErrorProps.email)
            return i18next.t('APPB2C.cj.personalDetails.error.missingEmail');
          return null;
        case commonErrorCodes.PredicateValidator:
          switch (property) {
            case personalDetailsErrorCodes.confirmEmail:
              return i18next.t('APPB2C.cj.personalDetails.error.wrongEmailConfirm');
            case personalDetailsErrorCodes.dob:
              return i18next.t('APPB2C.cj.ieltsReady.dobMin');

            default:
              return null;
          }
        case commonErrorCodes.PropertyValidationError:
          switch (property) {
            case commonErrorProps.streetAddress1:
              return i18next.t('APPB2C.cj.personalDetails.error.missingAddress');
            case commonErrorProps.postCode:
              return i18next.t('APPB2C.cj.personalDetails.error.missingZip');
            case commonErrorProps.state:
              return i18next.t('APPB2C.cj.personalDetails.error.missingState');
            case personalDetailsErrorCodes.gender:
              return i18next.t('APPB2C.cj.personalDetails.error.missingSex');
            case commonErrorProps.firstName:
              return i18next.t('APPB2C.common.basic.forms.onlyLatin');
            case commonErrorProps.surname:
              return i18next.t('APPB2C.common.basic.forms.onlyLatin');
            default:
              return null;
          }
        case commonErrorCodes.LengthValidator:
          switch (property) {
            case commonErrorProps.firstName:
              return i18next.t('APPB2C.common.basic.forms.firstNameMaxLength', {
                count: commonMaxLengths.name,
              });
            case commonErrorProps.surname:
              return i18next.t('APPB2C.common.basic.forms.surnameMaxLength', {
                count: commonMaxLengths.name,
              });
            case commonErrorProps.addressLine1:
            case commonErrorProps.addressLine2:
            case commonErrorProps.addressLine3:
            case commonErrorProps.addressLine4:
              return i18next.t('APPB2C.common.basic.forms.addressMaxLength', {
                count: commonMaxLengths.address,
              });
            case commonErrorProps.town:
              return i18next.t('APPB2C.common.basic.forms.townMaxLength', {
                count: commonMaxLengths.address,
              });
            case commonErrorProps.postCode:
              return i18next.t('APPB2C.common.basic.forms.postCodeMaxLength', {
                count: commonMaxLengths.postCode,
              });
            case commonErrorProps.state:
              return i18next.t('APPB2C.common.basic.forms.stateMaxLength', {
                count: commonMaxLengths.address,
              });

            default:
              return null;
          }
        case personalDetailsErrorCodes.DateShouldBeInThePast:
          return i18next.t('APPB2C.cj.personalDetails.error.wrongDob');
        case personalDetailsErrorCodes.TestTakerFailure:
          if (property === personalDetailsErrorCodes.plannedExamId)
            return i18next.t('APPB2C.cj.personalDetails.error.wrongDob');
          return null;
        case personalDetailsErrorCodes.EmailInUse:
          setShowEmailInUse && setShowEmailInUse(true);
          return null;
        case registrationErrorCodes.CountrySettingNotFound:
          return i18next.t('APPB2C.cj.review.error.wrongCountrySetting');

        default:
          return null;
      }
    });
  },
  tErrorMessageRegistration: ({
    errors,
    setShowRegistrationFailedModal,
    setRegistrationFailedCustomMessage,
    setShowMissingMarketingError,
    setMissingMarketingRoute,
    setShowChangedFeeModal,
    allMarketingQuestions,
    marketingAsnwerIds,
  }: ErrorMessageRegistrationProps): ErrorMessages | undefined => {
    const allErrors = reduceMultipleErrorSources(errors);

    if (!allErrors) return undefined;

    const nationalityOptionIds = allMarketingQuestions
      ?.find((m) => m.shortCode === 'CountryOfNationality')
      ?.options?.map((c) => c.id);

    const isMissingMarketingError = ({ code, property }: CodeOrProp) =>
      code?.includes('NumberOfQuestionsAndAnswersMustMatch') ||
      code?.includes('ExactlyOneAnswerShouldBeGivenForEveryQuestion') ||
      code?.includes('OpenAnswerIsRequired') ||
      code?.includes(AnswerInvalid) ||
      property?.includes('answers') ||
      false;

    const isTaxNumberError = ({ code, property }: CodeOrProp) =>
      property?.includes('taxNumber') ||
      property?.includes(registrationErrorCodes.TaxIdentifier) ||
      code?.includes(registrationErrorCodes.TaxIdentifier) ||
      code?.includes(registrationErrorCodes.InvalidInvoiceFormat) ||
      false;

    const isChangedFeeError = ({ code }: CodeOrProp) =>
      code?.includes(registrationErrorCodes.TotalFeeChanged) ||
      code?.includes(registrationErrorCodes.VoucherReserved) ||
      code?.includes(registrationErrorCodes.VoucherReserveFailed) ||
      false;

    const handleOtherCases = ({ code, property }: CodeOrProp): string | null => {
      if (code?.includes(registrationErrorCodes.MissingIdFile)) {
        setShowMissingMarketingError(true);
        setMissingMarketingRoute(appRoutes.journey.idDetails);
        return null;
      }

      if (isMissingMarketingError({ code, property })) {
        setShowMissingMarketingError(true);

        if (!marketingAsnwerIds?.some((a) => nationalityOptionIds?.some((n) => n === a))) {
          setMissingMarketingRoute(appRoutes.journey.idDetails);
        } else setMissingMarketingRoute(appRoutes.journey.ttProfile);

        return null;
      }

      if (isTaxNumberError({ code, property })) {
        return i18next.t('APPB2C.cj.review.taxNumberError');
      }

      if (code?.includes('NppResponseProcessor')) {
        return i18next.t('APPB2C.cj.review.error.wrongPaymentConfig');
      }

      if (isChangedFeeError({ code, property })) {
        setShowChangedFeeModal(true);
        return null;
      }

      setShowRegistrationFailedModal(true);
      return i18next.t('APPB2C.cj.review.registrationError');
    };

    return allErrors.map((e) => {
      const { code, property } = e;

      switch (code) {
        case commonErrorCodes.NotEmptyValidator:
          switch (property) {
            case commonErrorProps.email:
            case commonErrorProps.firstName:
            case commonErrorProps.surname:
            case commonErrorProps.streetAddress1:
            case commonErrorProps.town:
            case registrationErrorCodes.AddressTown:
            case registrationErrorCodes.companyName:
              return i18next.t('APPB2C.cj.review.error.missingOrderAcknowledgement');

            default:
              return null;
          }
        case commonErrorCodes.PredicateValidator:
          switch (property) {
            case registrationErrorCodes.taxNumber1:
              return i18next.t('APPB2C.cj.review.error.wrongTaxNumber');
            case registrationErrorCodes.pickUps:
              return i18next.t('APPB2C.cj.review.error.missingPickups');

            default:
              return null;
          }
        case commonErrorCodes.EmailValidator:
          if (property === commonErrorProps.email) {
            return i18next.t('APPB2C.cj.review.error.missingOrderAcknowledgement');
          }
          return null;
        case registrationErrorCodes.GreaterOrEqualZero:
          return i18next.t('APPB2C.cj.review.error.missingOrderAcknowledgement');
        case registrationErrorCodes.NotFound:
          setShowRegistrationFailedModal(true);
          return null;
        case registrationErrorCodes.CountrySettingNotFound:
          setRegistrationFailedCustomMessage(
            i18next.t('APPB2C.cj.review.error.wrongCountrySetting')
          );
          return null;

        default:
          return handleOtherCases({ code, property });
      }
    });
  },
};
