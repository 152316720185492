import { getUrlSearchParam } from '@britishcouncil/react-common';
import { MainContainer, PageContainer } from '@britishcouncil/react-solas-ors3';
import { IELTSProducts } from 'ors-api/iol';
import {
  AgentCandidateInvitationContextState,
  BatchIeltsModule,
  ProductsInCentre,
} from 'ors-api/mod';
import { ExternalLink, HtmlTextRaw } from 'ors-ui';
import { TermsShortCodeEnum, getOrganisationAlias, icLinks, makeTermsUrl } from 'ors-utils';
import { hasIeltsUsaUrl } from 'ors-translations';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { useChangePass } from 'common/useChangePass';
import { PageHeading } from 'components';
import { useIsPremiumCode, useSelector } from 'core';
import { appRoutes } from 'routing';
import { bookTest } from 'store/bookTest';
import { ExamType } from 'store/registration';
import { searchSelect } from 'store/searchSelect';
import { proceed } from 'store/startPage';
import { IeltsACIcon, IeltsGTIcon, IeltsUKVIIcon } from './ExamIcons';
import { InvitationModal } from './InvitationModal';
import { OrganisationNotes } from './OrganisationNotes';
import {
  BottomSection,
  StyledExamTitle,
  StyledExamType,
  StyledExamTypeBtn,
  WhichIeltsLabel,
} from './styles';

import { isValidExamType } from 'store/initApp/setPassedInData';
import { personalDetails } from 'store/personalDetails';
import { startPageRedirect } from 'store/startPageRedirect';

const StartPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useChangePass();
  const isUsa = hasIeltsUsaUrl();

  const organisation = useSelector((state) => state.organisationCountry?.organisationDetails?.data);
  const { isLoading: loadingIolAvailability } = useSelector(
    (state) => state.searchSelect.iolAvailable
  );
  const { invitationContext } = useSelector((state) => state.invitation);
  const { loadingFeatureFlags } = useSelector((state) => state.featureFlags);
  const orgAlias = getOrganisationAlias();
  const isUkvi = getUrlSearchParam('examFlow') === 'ukvi';
  const isPremiumCodeLink = useIsPremiumCode();

  useEffect(() => {
    dispatch(searchSelect.actions.softReset());
    dispatch(bookTest.thunks.clearExams());
    dispatch(personalDetails.actions.setAllowEdit(false));
    if (loadingFeatureFlags) {
      return;
    }

    const examType = getUrlSearchParam('examType') ?? '';
    const productId = getUrlSearchParam('productId') ?? '';

    if (!!productId) {
      dispatch(startPageRedirect(productId as IELTSProducts | ProductsInCentre));
    }

    if (!productId && isValidExamType(examType)) {
      dispatch(proceed(examType as ExamType, isUkvi));
    }
  }, [dispatch, loadingFeatureFlags, isUkvi]);

  useEffect(() => {
    const invitationIeltsModule = invitationContext?.data?.ieltsModule;
    if (invitationIeltsModule && invitationIeltsModule !== BatchIeltsModule.Both) {
      dispatch(proceed(invitationIeltsModule === BatchIeltsModule.Academic ? 'ac' : 'gt', isUkvi));
    }
  }, [dispatch, invitationContext.data, isUkvi]);

  const heading: string = organisation?.landingPageTitle || t('APPB2C.common.startPage.title');

  const termsAndConditions = makeTermsUrl({
    shortCode: organisation?.globalTermsAndConditions?.shortCode ?? TermsShortCodeEnum.Global_IELTS,
  });

  const showUkvi =
    !invitationContext.data && !isPremiumCodeLink && (organisation?.hasUkviContext ?? true);

  return (
    <article>
      {invitationContext.data &&
        invitationContext?.data?.state !== AgentCandidateInvitationContextState.Active && (
          <InvitationModal />
        )}
      <PageHeading heading={heading} />

      <PageContainer
        loading={invitationContext.isLoading || loadingIolAvailability || loadingFeatureFlags}
      >
        <OrganisationNotes orgSpecificData={organisation} />
        <h2>{t('APPB2C.common.startPage.subtitle')}</h2>
        <StyledExamType>
          <StyledExamTitle className="notranslate">
            {t('APPB2C.common.startPage.ielts')}
          </StyledExamTitle>
          <StyledExamTypeBtn
            icon={<IeltsACIcon />}
            title={t('APPB2C.common.startPage.options.ac')}
            description={t('APPB2C.common.startPage.description.ac')}
            onClick={() => dispatch(proceed('ac'))}
            aria-label={
              t('APPB2C.common.startPage.book') + ' ' + t('APPB2C.common.startPage.options.ac')
            }
            id="select-ac"
          />
          <StyledExamTypeBtn
            icon={<IeltsGTIcon />}
            title={t('APPB2C.common.startPage.options.gt')}
            description={t('APPB2C.common.startPage.description.gt')}
            onClick={() => dispatch(proceed('gt'))}
            aria-label={
              t('APPB2C.common.startPage.book') + ' ' + t('APPB2C.common.startPage.options.gt')
            }
            id="select-gt"
          />
        </StyledExamType>
        {showUkvi && (
          <StyledExamType>
            <StyledExamTitle className="notranslate">
              {t('APPB2C.common.startPage.ieltsUkvi')}
            </StyledExamTitle>

            <StyledExamTypeBtn
              icon={<IeltsUKVIIcon />}
              title={t('APPB2C.cj.ukviStartPage.title')}
              description={t('APPB2C.common.startPage.description.ukvi')}
              onClick={() => {
                return navigate(appRoutes.search.ukvi);
              }}
              id="goto-ukvi"
              aria-label={
                t('APPB2C.common.startPage.book') +
                ' ' +
                t('APPB2C.common.startPage.options.ukvi.v2')
              }
            />
          </StyledExamType>
        )}
        <WhichIeltsLabel data-testid="which-ielts">
          <ExternalLink
            className="link"
            href={organisation?.versionOfIeltsUrl ?? icLinks.whichIelts}
          >
            {t('APPB2C.common.startPage.whichIELTS')}
          </ExternalLink>
        </WhichIeltsLabel>
      </PageContainer>
      <MainContainer>
        <BottomSection>
          {isUsa && (
            <p>
              {t('APPB2C.common.startPage.readTnCs')}&nbsp;
              <Link className="link" to={termsAndConditions} target="_blank" data-testid="tncs">
                {t('APPB2C.common.startPage.tncs')}
              </Link>
            </p>
          )}
          {orgAlias && organisation?.bottomLandingPageCustomText ? (
            <HtmlTextRaw htmlText={organisation.bottomLandingPageCustomText || ''} />
          ) : (
            <p>{t('APPB2C.common.startPage.endNote')}</p>
          )}
        </BottomSection>
      </MainContainer>
    </article>
  );
};

export default StartPage;
