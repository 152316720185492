import styled from '@emotion/styled';

import { AnimatedButton } from 'ors-ui';

export const BottomSection = styled.div`
  p {
    color: #444;
    margin-bottom: 1em;
    font-size: 14px;
  }

  p a {
    font-size: inherit;
  }
`;

export const StyledExamTypeBtn = styled(AnimatedButton)`
  width: 100%;

  & + & {
    margin-top: 24px;
  }
`;

export const WhichIeltsLabel = styled.label`
  margin-bottom: 0;
  margin-top: 22px;
  a {
    font-size: 0.9em;
  }
`;

export const StyledExamTitle = styled.div`
  color: #54565a;
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: bold;

  [dir='rtl'] & {
    font-weight: 500;
  }
`;

export const StyledExamType = styled.div`
  & + & {
    margin-top: 32px;
  }
`;

export const StyledList = styled.ul`
  li {
    list-style-type: disc;
  }
`;
